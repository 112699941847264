// import { nanoid } from 'nanoid';

const headData = {
  title: 'Music Looper',
  lang: 'en',
  siteUrl: 'https://loop-player.adamliu.app/',
  description:
    'Music Looper is audio Music Looper. It supports MP3 and Mp4 files. Designed for learning the foreign language and musical instruments.',
};

const appInfo = {
  title: 'Music Looper',
  subtitle:
    'Music Looper is audio Music Looper. It supports MP3 and Mp4 files. Designed for learning the foreign language and musical instruments.',
  iosLink: 'https://apps.apple.com/us/app/music-looper-audio-repeater/id1596320707',
  aosLink: '',
  appIcon: 'loop_player_icon.png',
  appPreview: 'preview_1.png',
  mailTo: 'mailto:adamliu2403@gmail.com?subject=Loop player query',
  email: 'adamliu2403@gmail.com',
};

module.exports = {
  headData,
  appInfo,
};
